<template>
	<!-- 步骤二-机构 -->
	<div class="stepTwoOrganize-page-all">
		<div class="stepTwoOrganize-pageTitle">机构入驻</div>
		<div class="stepTwoOrganize-pageLine"></div>
		<!-- 标签 -->
		<stepitem type="2"></stepitem>
		<!-- 填写信息正文 -->
		<div class="stepTwoOrganize-pageForm">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<!-- 申请人身份信息 -->
				<div class="stepTwoOrganize-pagestepTwoOrganize-formTitle">
					<span></span>
					<span>申请人身份信息</span>
				</div>
				<el-form-item label="申请人" prop="truename">
					<el-input v-model="ruleForm.truename" maxlength="10" placeholder="需与身份证一致" class="stepTwoOrganize-handleInput"></el-input>
				</el-form-item>
				<el-form-item label="身份证号码" prop="IDcard">
					<el-input v-model="ruleForm.IDcard" maxlength="18" placeholder="请输入身份证号码" class="stepTwoOrganize-handleInput"></el-input>
				</el-form-item>
				<el-form-item label="身份证件照" prop="IDcardimg">
					<el-upload class="avatar-uploader" action="123" :show-file-list="false" name="images" accept="image/*" :on-success="handleAvatarSuccess"
					 :before-upload="beforeAvatarUpload">
						<el-image v-if="ruleForm.IDcardimg" :src="ruleForm.IDcardimg" class="avatar"></el-image>
						<div v-else>
							<i class="el-icon-plus avatar-uploader-icon"></i>
							<div>点击上传图片</div>
						</div>
					</el-upload>
					<div class="stepTwoOrganize-uploadTips stepTwoOrganize-w421">
						请上传手持护照的半身照<br>
						文件小于6M<br>
						支持JPG/PNG/BMP等格式图片
					</div>
					<img src="../../../assets/img/organize/IDcard.png" class="stepTwoOrganize-idcardIcon">
					<div class="stepTwoOrganize-uploadTips1">
						1、手臂完整露出。<br>
						2、身份证信息清晰可见。
					</div>
				</el-form-item>
				<el-form-item label="手机号码" prop="mobile">
					<el-input v-model="ruleForm.mobile" maxlength="11" placeholder="请填写手机号码" class="stepTwoOrganize-handleInput stepTwoOrganize-stepTwoOrganize-ml20"></el-input>
					<el-input v-model="codeData" maxlength="6" placeholder="请填写验证码" class="stepTwoOrganize-stepTwoOrganize-ml20"></el-input>
					<el-button @click="toVerify()">{{codetext}}</el-button>
				</el-form-item>
				<el-form-item label="邮箱" prop="email">
					<el-input v-model="ruleForm.email" placeholder="请填写邮箱" class="stepTwoOrganize-handleInput"></el-input>
				</el-form-item>
				<!-- 机构主页信息 -->
				<div class="stepTwoOrganize-pageLine stepTwoOrganize-mr"></div>
				<div class="stepTwoOrganize-pagestepTwoOrganize-formTitle">
					<span></span>
					<span>机构主页信息</span>
				</div>
				<el-form-item label="机构Logo" prop="school_logo">
					<el-upload class="avatar-uploader" style="float: left;" action="123" :show-file-list="false" name="images" accept="image/*"
					 :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload1">
						<el-image v-if="ruleForm.school_logo" :src="ruleForm.school_logo" class="avatar"></el-image>
						<div v-else>
							<i class="el-icon-plus avatar-uploader-icon"></i>
							<div>点击上传图片</div>
						</div>
					</el-upload>
					<div class="stepTwoOrganize-uploadTips4">
						尺寸不小于130X130，文件小于6M<br>
						支持JPG/PNG/BMP等格式图片<br>
						请上传本人真实头像，通过审核后将显示在老师主页，暂不提供修改
					</div>
				</el-form-item>
				<el-form-item label="机构名称" prop="school_name">
					<el-input v-model="ruleForm.school_name" maxlength="12" placeholder="请填写机构名称" class="stepTwoOrganize-handleInput"
					 style="float: left;"></el-input>
					<div class="stepTwoOrganize-uploadTips4">注：机构名称和机构LOGO名称与营业执照名称一致或有营业执照的关键字，如不一致需提供商标注册权</div>
				</el-form-item>
				<el-form-item label="机构简介" prop="school_info" class="stepTwoOrganize-handleTextarea">
					<el-input type="textarea" maxlength="150" v-model="ruleForm.school_info" rows="10" placeholder="比如：我是会画画的baby，我有奔驰的小野马，和带刺的玫瑰，欢迎找我玩耍呀。（展现在老师的个人主页上，建议以精炼的语言介绍自己的优势和特点。）"></el-input>
				</el-form-item>
				<el-form-item label="主营类目" prop="cat_id">
					<el-cascader v-model="value" :options="options" @change="handleChange" placeholder="请选择分类"></el-cascader>
					<div class="stepTwoOrganize-uploadTips4 stepTwoOrganize-w421">注：主营类目一旦选定将不支持修改该分类与可开设课程类型一致</div>
				</el-form-item>
				<el-form-item label="机构邮箱" prop="school_email">
					<el-input v-model="ruleForm.school_email" placeholder="请填写机构邮箱" class="stepTwoOrganize-handleInput"></el-input>
				</el-form-item>
				<!-- 机构经营信息 -->
				<div class="stepTwoOrganize-pageLine stepTwoOrganize-mr"></div>
				<div class="stepTwoOrganize-pagestepTwoOrganize-formTitle">
					<span></span>
					<span>机构经营信息</span>
				</div>
				<el-form-item label="公司名称" class="stepTwoOrganize-handleTextarea" prop="company_name">
					<el-input v-model="ruleForm.company_name" maxlength="20" placeholder="请填写公司名称"></el-input>
				</el-form-item>
				<el-form-item label="机构性质" prop="school_type">
					<el-radio-group v-model="ruleForm.school_type">
						<el-radio :label="1">持有营业执照</el-radio>
						<el-radio :label="2">不持有营业执照</el-radio>
					</el-radio-group>
				</el-form-item>
				<!-- 持有营业执照 -->
				<div v-show="this.ruleForm.school_type==1">
					<div class="stepTwoOrganize-formItem">
						<div class="stepTwoOrganize-formTitle">
							<span style="color: red;">*</span>
							营业执照
						</div>
						<el-upload class="avatar-uploader" action="123" :show-file-list="false" name="images" accept="image/*"
						 :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload2">
							<el-image v-if="ruleForm.yyzz_img" :src="ruleForm.yyzz_img" class="avatar"></el-image>
							<div v-else>
								<i class="el-icon-plus avatar-uploader-icon"></i>
								<div>点击上传图片</div>
							</div>
						</el-upload>
						<div class="stepTwoOrganize-uploadTips stepTwoOrganize-w421">
							请上传有年检章的企业营业执照副本扫描件<br>
							文件小于6M<br>
							支持JPG/PNG/BMP等格式图片
						</div>
						<img src="../../../assets/img/organize/yyzz.png" class="stepTwoOrganize-yyzzIcon">
					</div>
					<div class="stepTwoOrganize-formItem stepTwoOrganize-mr">
						<div class="stepTwoOrganize-formTitle">
							<span style="color: red;">*</span>
							营业执照注册号
						</div>
						<el-input v-model="ruleForm.yyzz_num" maxlength="25" placeholder="请填写营业执照上的注册号或统一社会信用代码" class="stepTwoOrganize-w421"></el-input>
						<div class="stepTwoOrganize-uploadTips2">注：如您的营业执照经营范围没有教育、培训或咨询。请上传教育资质证书，如办学许可证</div>
					</div>
				</div>
				<div class="stepTwoOrganize-formItem stepTwoOrganize-mr" v-show="this.ruleForm.school_type==1">
					<div class="stepTwoOrganize-formTitle">
						<span style="color: red;">*</span>
						证件有效期
					</div>
					<el-radio-group v-model="ruleForm.yyzz_due_type">
						<el-radio :label="1">长期有效</el-radio>
						<el-radio :label="2">固定时限</el-radio>
						<!-- 固定时限 -->
						<el-date-picker v-show="this.ruleForm.yyzz_due_type==2" v-model="ruleForm.yyzz_due_time" type="date" placeholder="选择日期"
						 class="ml20px" value-format="yyyy-MM-dd"></el-date-picker>
					</el-radio-group>
				</div>
				<div class="stepTwoOrganize-formItem stepTwoOrganize-mr">
					<div class="stepTwoOrganize-formTitle">
						<span style="color: red;" v-show="this.ruleForm.school_type==2">*</span>
						教育资质证书
					</div>
					<el-upload class="avatar-uploader" action="123" :show-file-list="false" name="images" accept="image/*" :on-success="handleAvatarSuccess"
					 :before-upload="beforeAvatarUpload3">
						<el-image v-if="ruleForm.teach_img" :src="ruleForm.teach_img" class="avatar"></el-image>
						<div v-else>
							<i class="el-icon-plus avatar-uploader-icon"></i>
							<div>点击上传图片</div>
						</div>
					</el-upload>
					<div class="stepTwoOrganize-uploadTips stepTwoOrganize-w421">
						请上传有年检章的企业营业执照副本扫描件<br>
						文件小于6M<br>
						支持JPG/PNG/BMP等格式图片
					</div>
				</div>
				<div class="stepTwoOrganize-formItem stepTwoOrganize-mr">
					<div class="stepTwoOrganize-formTitle">
						<span style="color: red;" v-show="this.ruleForm.school_type==2">*</span>
						证书编号
					</div>
					<el-input v-model="ruleForm.teach_num" maxlength="25" placeholder="请填写证书编号" class="stepTwoOrganize-w421"></el-input>
				</div>
				<div>
					<div class="stepTwoOrganize-formItem stepTwoOrganize-mr">
						<div class="stepTwoOrganize-formTitle">
							<span style="color: red;" v-show="this.ruleForm.school_type==2">*</span>
							证件有效期
						</div>
						<el-radio-group v-model="ruleForm.teach_due_type">
							<el-radio :label="1">长期有效</el-radio>
							<el-radio :label="2">固定时限</el-radio>
						</el-radio-group>
						<!-- 固定时限 -->
						<el-date-picker v-show="this.ruleForm.teach_due_type==2" style="color: #00D0B0;" v-model="ruleForm.teach_due_time"
						 type="date" placeholder="选择日期" class="ml20px" value-format="yyyy-MM-dd"></el-date-picker>
					</div>
				</div>
				<el-form-item label="承诺书" prop="commitment">
					<el-upload class="avatar-uploader" style="float: left;" action="123" :show-file-list="false" name="images" accept="image/*"
					 :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload4">
						<el-image v-if="ruleForm.commitment" :src="ruleForm.commitment" class="avatar"></el-image>
						<div v-else>
							<i class="el-icon-plus avatar-uploader-icon"></i>
							<div>点击上传图片</div>
						</div>
					</el-upload>
					<div class="stepTwoOrganize-uploadTips4 stepTwoOrganize-w421">
						请填写完毕本页面的信息后，点击下载<span style="color: #00D0B0;">云课堂承诺书，</span><br>
						上传<span style="color: #00D0B0;">亲笔签名、公司公章</span>后的传扫描件<br>
						文件小于6M<br>
						支持JPG/PNG/BMP等格式图片
					</div>
				</el-form-item>
				<!-- 下一步按钮 -->
				<div class="stepTwoOrganize-pageButton" @click="toNext()">提交资料</div>
			</el-form>
		</div>

	</div>
</template>

<script>
	import stepitem from '../../common/stepitem.vue';
	import axios from 'axios'
	export default {
		components: {
			stepitem
		},
		data() {
			return {
				// 表单
				ruleForm: {
					truename: '',
					IDcard: '',
					IDcardimg: '',
					mobile: '',
					school_logo: '',
					school_name: '',
					company_name: '',
					school_type: 1,
					email: '',
					school_email: '',
					school_info: '',
					yyzz_img: '',
					yyzz_num: '',
					yyzz_due_type: 1,
					yyzz_due_time: '',
					teach_img: '',
					teach_num: '',
					teach_due_type: 1,
					teach_due_time: '',
					commitment: '',
					code: '',
					cat_id: '',
				},
				rules: {
					truename: [{
						required: true,
						message: '请输入申请人姓名',
						trigger: 'blur'
					}],
					IDcard: [{
						required: true,
						message: '请输入身份证号码',
						trigger: 'blur'
					}],
					IDcardimg: [{
						required: true,
						message: '请上传身份证件照',
						trigger: 'change'
					}],
					mobile: [{
						required: true,
						message: '请输入手机号码',
						trigger: 'blur'
					}],
					email: [{
						required: true,
						message: '请填写邮箱',
						trigger: 'blur'
					}],
					school_logo: [{
						required: true,
						message: '请上传机构logo',
						trigger: 'change'
					}],
					school_name: [{
						required: true,
						message: '请输入机构名称',
						trigger: 'blur'
					}],
					school_info: [{
						required: true,
						message: '请输入机构简介',
						trigger: 'blur'
					}],
					cat_id: [{
						required: true,
						message: '请选择主营类目',
						trigger: 'blur'
					}],
					school_email: [{
						required: true,
						message: '请填写机构邮箱',
						trigger: 'blur'
					}],
					company_name: [{
						required: true,
						message: '请填写公司名称',
						trigger: 'blur'
					}],
					school_type: [{
						required: true,
						message: '请选择机构性质',
						trigger: 'blur'
					}],
					commitment: [{
						required: true,
						message: '请上传承诺书',
						trigger: 'change'
					}]
				},
				// 上传
				posts: "https://ykt.qdy2020.com/index/uploadimage",
				whichPic: 1,
				// 资格证书选择框
				which: 1,
				datatype: [{
						id: 1,
						name: "教师认证"
					},
					{
						id: 2,
						name: "高学历认证"
					},
					{
						id: 3,
						name: "专业认证"
					},
					{
						id: 4,
						name: "微博认证号"
					},
					{
						id: 5,
						name: "其他认证"
					},
				],
				// 下拉框
				value: [],
				options: [{
					value: 'zhinan',
					label: '指南',
					children: [{
						value: 'shejiyuanze',
						label: '设计原则',
						children: [{
							value: 'yizhi',
							label: '一致'
						}, {
							value: 'fankui',
							label: '反馈'
						}, {
							value: 'xiaolv',
							label: '效率'
						}, {
							value: 'kekong',
							label: '可控'
						}]
					}],
				}],
				// 机构性质单选框
				radio: 1,
				radio1: 1,
				radio2: 1,
				// 手机验证码
				codeData: '',
				codetext: "手机验证码",
				timer: '',
				tclick: 1,
			};
		},
		created() {
			this.resId = this.$route.query.id
			this.getCateData()
		},
		beforeDestroy() {
			clearInterval(this.timer);
			this.value = "获取验证码"
		},
		methods: {
			// 提交按钮
			toNext() {
				if (
					!this.ruleForm.truename ||
					!this.ruleForm.IDcard ||
					!this.ruleForm.IDcardimg ||
					!this.ruleForm.mobile ||
					!this.codeData ||
					!this.ruleForm.school_logo ||
					!this.ruleForm.school_name ||
					!this.ruleForm.company_name ||
					!this.ruleForm.school_type ||
					!this.ruleForm.cat_id ||
					!this.ruleForm.email ||
					!this.ruleForm.school_email ||
					!this.ruleForm.school_info
				) {
					this.$message.error("请认真完善所有资料信息")
					return
				}
				this.$post('school/schoolenter', {
					truename: this.ruleForm.truename,
					IDcard: this.ruleForm.IDcard,
					IDcardimg: this.ruleForm.IDcardimg,
					mobile: this.ruleForm.mobile,
					code: this.codeData,
					school_logo: this.ruleForm.school_logo,
					school_name: this.ruleForm.school_name,
					company_name: this.ruleForm.company_name,
					school_type: this.ruleForm.school_type,
					cat_id: this.ruleForm.cat_id,
					email: this.ruleForm.email,
					school_email: this.ruleForm.school_email,
					school_info: this.ruleForm.school_info,
					yyzz_img: this.ruleForm.yyzz_img,
					yyzz_num: this.ruleForm.yyzz_num,
					yyzz_due_type: this.ruleForm.yyzz_due_type,
					yyzz_due_time: this.ruleForm.yyzz_due_time,
					teach_img: this.ruleForm.teach_img,
					teach_num: this.ruleForm.teach_num,
					teach_due_type: this.ruleForm.teach_due_type,
					teach_due_time: this.ruleForm.teach_due_time,
					commitment: this.ruleForm.commitment
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg);
						this.$router.push({
							path: '/stepThree',
							query: {
								e: 1
							}
						})
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 获取主营类目
			getCateData() {
				this.$get('goodscate/getgoodscatetreelist', {}).then(response => {
					if (response.error == 0) {
						for (var i = 0; i < response.data.goodsCateTreeList.length; i++) {
							response.data.goodsCateTreeList[i].value = response.data.goodsCateTreeList[i].id
							response.data.goodsCateTreeList[i].label = response.data.goodsCateTreeList[i].cat_name
							for (var j = 0; j < response.data.goodsCateTreeList[i].children.length; j++) {
								response.data.goodsCateTreeList[i].children[j].value = response.data.goodsCateTreeList[i].children[j].id
								response.data.goodsCateTreeList[i].children[j].label = response.data.goodsCateTreeList[i].children[j].cat_name
								for (var k = 0; k < response.data.goodsCateTreeList[i].children[j].children.length; k++) {
									response.data.goodsCateTreeList[i].children[j].children[k].value = response.data.goodsCateTreeList[i].children[
										j].children[k].id
									response.data.goodsCateTreeList[i].children[j].children[k].label = response.data.goodsCateTreeList[i].children[
										j].children[k].cat_name
									if (k == (response.data.goodsCateTreeList[i].children[j].children.length - 1)) {
										this.options = response.data.goodsCateTreeList
									}
								}
							}
						}
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 选择分类按钮
			handleChange(e) {
				this.ruleForm.cat_id = e[2]
			},

			// 发送验证码
			toVerify() {
				if(this.tclick==2){
					return
				}
				this.$get('code/sendverifycode', {
					phone: this.ruleForm.mobile
				}).then(response => {
					if (response.error == 0) {
						//this.codeData = response.data.code
						this.tclick = 2
						this.codetext = 60
						this.timer = setInterval(() => {
							this.codetext -- 
							if (this.codetext == 0) {
								this.tclick = 1
								this.codetext = "再次获取"
								clearInterval(this.timer);
							}
						}, 1000);
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 上传图片
			handleAvatarSuccess(res, file) {},
			beforeAvatarUpload(file) {
				this.postpicture(file, 1)
			},
			beforeAvatarUpload1(file) {
				this.postpicture(file, 2)
			},
			beforeAvatarUpload2(file) {
				this.postpicture(file, 3)
			},
			beforeAvatarUpload3(file) {
				this.postpicture(file, 4)
			},
			beforeAvatarUpload4(file) {
				this.postpicture(file, 5)
			},
			postpicture(file, whichPic) {
				let param = new FormData(); // 创建form对象
				param.append('images', file); // 将文件存入file下面
				let config = {
					header: {
						'content-type': 'multipart/form-data'
					},
				}
				axios.post(this.posts, param, config)
					.then(res => {
						if (whichPic == 1) {
							this.ruleForm.IDcardimg = res.data.data
						}
						if (whichPic == 2) {
							this.ruleForm.school_logo = res.data.data
						}
						if (whichPic == 3) {
							this.ruleForm.yyzz_img = res.data.data
						}
						if (whichPic == 4) {
							this.ruleForm.teach_img = res.data.data
						}
						if (whichPic == 5) {
							this.ruleForm.commitment = res.data.data
						}
					}).catch(err => {
						console.log(err)
					})
			},
		}
	}
</script>

<style>
	.stepTwoOrganize-page-all {
		width: 65%;
		height: auto;
		margin: 0 auto;
		position: relative;
		top: 40px;
		margin-bottom: 52px;
		background-color: white;
		overflow: hidden;
	}

	.stepTwoOrganize-pageTitle {
		width: 100%;
		height: 130px;
		font-size: 48px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #151515;
		line-height: 130px;
		text-align: center;
	}

	.stepTwoOrganize-pageLine {
		width: 100%;
		height: 1px;
		background: #E6E6E6;
	}

	.stepTwoOrganize-pageForm {
		width: 90%;
		height: auto;
		margin: 0 auto;
	}

	.stepTwoOrganize-pagestepTwoOrganize-formTitle {
		width: 100%;
		height: 22px;
		margin-bottom: 20px;
	}

	.stepTwoOrganize-pagestepTwoOrganize-formTitle span:first-child {
		display: inline-block;
		width: 3px;
		height: 20px;
		background: #00D0B0;
		float: left;
	}

	.stepTwoOrganize-pagestepTwoOrganize-formTitle span:last-child {
		display: inline-block;
		font-size: 22px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 18px;
		margin-left: 10px;
	}

	.stepTwoOrganize-handleInput {
		width: 200px !important;
	}

	.stepTwoOrganize-handleInput1 {
		width: 400px;
		position: absolute;
		margin-top: -100px;
		margin-left: 170px;
	}

	.stepTwoOrganize-handleTextarea {
		width: 500px;
	}

	.stepTwoOrganize-classType {
		width: 140px;
		height: 250px;
		background-color: #F6F6F6;
		text-align: center;
		line-height: 50px;
	}

	.stepTwoOrganize-textType {
		width: 140px;
		height: 50px;
		font-size: 18px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
	}

	.stepTwoOrganize-textTypeIn {
		width: 140px;
		height: 50px;
		background: #00D0B0;
		font-size: 18px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
	}

	.stepTwoOrganize-addEduButton {
		width: 400px;
		height: 30px;
		background: #EEEEEE;
		border: 1px solid #CCCCCC;
		border-radius: 8px;
		text-align: center;
		line-height: 30px;
		margin-top: 10px;
	}

	.stepTwoOrganize-addIcon {
		position: absolute;
		margin-left: -30px;
		margin-top: 3px;
	}

	.stepTwoOrganize-formItem {
		margin-left: 100px;
	}

	.stepTwoOrganize-formTitle {
		font-size: 14px;
		color: #606266;
		line-height: 40px;
	}

	.stepTwoOrganize-pageButton {
		width: 146px;
		height: 54px;
		background: #00D0B0;
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 54px;
		text-align: center;
		margin: 66px 100px;
	}

	.stepTwoOrganize-mr {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.stepTwoOrganize-stepTwoOrganize-ml20 {
		margin-right: 10px;
		width: 150px;
	}

	.ml20 {
		margin-left: -20px;
	}

	.stepTwoOrganize-w421 {
		width: 421px;
	}

	.stepTwoOrganize-avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.stepTwoOrganize-uploadTips {
		width: 200px;
		height: 90px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		color: #ACACAC;
		position: absolute;
		margin-top: -130px;
		margin-left: 210px;
	}

	.stepTwoOrganize-idcardIcon {
		position: absolute;
		margin-top: -135px;
		margin-left: 440px;
		width: 198px;
		height: 124px;
	}

	.stepTwoOrganize-yyzzIcon {
		position: absolute;
		margin-top: -135px;
		margin-left: 550px;
		width: 90px;
		height: 124px;
	}

	.stepTwoOrganize-uploadTips1 {
		width: 125px;
		height: 90px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		color: #ACACAC;
		position: absolute;
		margin-top: -130px;
		margin-left: 650px;
	}

	.stepTwoOrganize-uploadTips2 {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #ACACAC;
		margin-top: 10px;
	}

	.stepTwoOrganize-uploadPosition {
		position: absolute;
		margin-top: -240px;
		margin-left: 170px;
	}

	.stepTwoOrganize-uploadTips3 {
		width: 293px;
		height: 76px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		color: #ACACAC;
		position: absolute;
		margin-top: -230px;
		margin-left: 390px;
	}

	.stepTwoOrganize-uploadTips4 {
		width: 421px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		color: #ACACAC;
		display: inline-block;
		margin-left: 10px;
	}

	.stepTwoOrganize-biaoqian2 {
		margin-left: -5px;
		position: absolute;
		z-index: 2;
		width: 343px;
		height: auto;
	}

	.stepTwoOrganize-biaoqian1 {
		position: absolute;
		z-index: 1;
		width: 343px;
		height: auto;
	}

	i {
		margin-top: 0;
	}

	.ml20px {
		margin-left: 20px !important;
	}
</style>
